// import React from 'react';
// import './Messages.css'; 
// import MessageImg from "../../assets/messagingmain.png";

// const serviceData = [
//   {
//     id: 1,

//     title: 'One-on-One Chat',
//     description: 'Enables direct, real-time messaging between an employer and an individual employee for  work-related discussions.',
//   },
 
//   {
//     id: 2,

//     title: 'Branch/Department Messaging',
//     description: ' Send messages to specific branches or departments to ensure relevant information reaches the right group.',
//   },
// ];

// const Messages = () => {
//   return (
//     <div className="Message-card bg-custom-bg mb-10 mt-10 pb-10">
//         <h4 className=" text-xl font-bold text-customPurple text-center  py-10 px-10">Messages</h4>
//        <div className="Message-container">
     
//      <div className="image-section">
//        <img src={MessageImg} alt="Messaging Service" />
//      </div>

//      <div className="content-section">
//        {serviceData.map((service) => (
//          <div key={service.id} className={`service-box ${service.id % 2 === 0 ? 'right-align' : 'left-align'}`}>
  
//            <div className="content">
//              <h3 className='text-sm font-bold pb-2'>{service.title}</h3>
//              <p className='text-xs leading-10'>{service.description}</p>
//            </div>
//          </div>
//        ))}
//      </div>
//    </div>
     
//     </div>
   
//   );
// };

// export default Messages;

import React from 'react';
import './Messages.css'; // Custom CSS file for styling
import MessageImg from "../../assets/messagingmain.png";

// Sample data for services
const serviceData = [
  {
    id: 1,
    title: 'Celebratory Banners',
    description: 'Send customized banners for Employee of the Month, birthdays, and work anniversaries to celebrate achievements and boost workplace culture',
  },
  {
    id: 2,
    title: 'Branch/Department Messaging',
    description: (
      <>

      Send messages to branches or departments to ensure relevant information reaches the right employees efficiently
    <br></br><br></br>
      </>
    ),
  },
];

const Messages = () => {
  return (
    <div className="Message-card bg-custom-bg mb-10 mt-10">
      <h4 className="text-xl font-bold text-customPurple text-center py-10 px-10">Messages</h4>
      <div className="Message-container px-10">
        {/* Left Side: ID 1 Content */}
        <div className="content-section left-content">
          <div key={serviceData[0].id} className="service-box left-align">
            <div className="content">
              <h3 className="text-sm font-bold pb-2 ">{serviceData[0].title}</h3>
              <p className="text-xs leading-10 pt-4">{serviceData[0].description}</p>
            </div>
          </div>
        </div>

        {/* Center: Image */}
        <div className="image-messection sm:px-10">
          <img src={MessageImg} alt="Messaging Service" />
        </div>

        {/* Right Side: ID 3 Content */}
        <div className="content-section right-content mb-4">
          <div key={serviceData[1].id} className="service-box right-align">
            <div className="content">
              <h3 className="text-sm font-bold pb-2 leading-10">{serviceData[1].title}</h3>
              <p className="text-xs leading-10">{serviceData[1].description}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Messages;

