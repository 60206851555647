import React, { useState } from "react";
import { HashLink } from 'react-router-hash-link';
import MenuIcon from "../../assets/icons8-menu.svg"; // Ensure this path is correct for your project
import easyLogo from "../../assets/easylogo.png"; // Adjusted the path

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <nav className="bg-gray-100 shadow-md fixed w-full z-50">
      <div className="mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center py-10 h-16">
          <div className="flex items-center gap-8"> {/* Increased gap */}
            <img
              src={easyLogo}
              alt="EasyPagar Logo"
              className="h-20 w-auto md:h-[60px] h-[50px] sm:w-auto"
            />
            <div className="hidden md:flex flex-col">
              <a href="/#home" className="text-xs font-bold text-gray-800 leading-8">
                Easy Pagar
              </a>
              <p className="text-xs font-semibold text-blue-shade2">Ab Pagar Hua Easy</p>
            </div>
          </div>
          <div className="flex-grow"></div> {/* This pushes the nav links to the right */}
          <div className="hidden md:flex md:space-x-8 "> {/* Adjusted space between nav items */}
            <HashLink
              to="/#home"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              className="text-gray-800 hover:text-yellow-400 px-3 py-2 rounded-md text-xs font-medium cursor-pointer"
            >
              Home
            </HashLink>
            <HashLink
              to="/#attendence"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              className="text-gray-800 hover:text-yellow-400 px-3 py-2 rounded-md text-xs font-medium cursor-pointer"
            >
              Products
            </HashLink>
            <HashLink
              to="/#pricing"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              className="text-gray-800 hover:text-yellow-400 px-3 py-2 rounded-md text-xs font-medium cursor-pointer"
            >
              Plans & Pricing
            </HashLink>
            <HashLink
              to="/#partners"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              className="text-gray-800 hover:text-yellow-400 px-3 py-2 rounded-md text-xs font-medium cursor-pointer"
            >
              Partners
            </HashLink>
            <HashLink
              to="/#testimonials"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              className="text-gray-800 hover:text-yellow-400 px-3 py-2 rounded-md text-xs font-medium cursor-pointer"
            >
              Help
            </HashLink>
            {/* <HashLink
              to="/#Faq"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              className="text-gray-800 hover:text-yellow-400 px-3 py-2 rounded-md text-xs font-medium cursor-pointer"
            >
              Faq
            </HashLink> */}
            <HashLink
              to="/#contact"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              className="text-gray-800 hover:text-yellow-400 px-3 py-2 rounded-md text-xs font-medium cursor-pointer"
            >
              Contact Us
            </HashLink>
          </div>
          <div className="-mr-2 flex md:hidden">
            <button
              onClick={() => setIsOpen(!isOpen)}
              type="button"
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-800 hover:text-yellow-400 focus:outline-none"
              aria-controls="mobile-menu"
              aria-expanded={isOpen}
            >
              <img src={MenuIcon} alt="Menu" className="h-6 w-6" />
            </button>
          </div>
        </div>
      </div>

      {isOpen && (
        <div className="md:hidden" id="mobile-menu">
          <div className="px-2 text-center pt-2 pb-3 space-y-1 sm:px-3">
            <HashLink
              to="/#hero2"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              className="text-gray-800 hover:text-yellow-400 block px-3 py-2 rounded-md text-base font-medium cursor-pointer"
              onClick={() => setIsOpen(false)}
            >
              Home
            </HashLink>
            <HashLink
              to="/#attendence"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              className="text-gray-800 hover:text-yellow-400 block px-3 py-2 rounded-md text-base font-medium cursor-pointer"
              onClick={() => setIsOpen(false)}
            >
              Products
            </HashLink>
            <HashLink
              to="/#pricing"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              className="text-gray-800 hover:text-yellow-400 block px-3 py-2 rounded-md text-base font-medium cursor-pointer"
              onClick={() => setIsOpen(false)}
            >
             Plans & Pricing
            </HashLink>
            <HashLink
              to="/#partners"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              className="text-gray-800 hover:text-yellow-400 block px-3 py-2 rounded-md text-base font-medium cursor-pointer"
              onClick={() => setIsOpen(false)}
            >
             Partners
            </HashLink>
            <HashLink
              to="/#testimonials"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              className="text-gray-800 hover:text-yellow-400 block px-3 py-2 rounded-md text-base font-medium cursor-pointer"
              onClick={() => setIsOpen(false)}
            >
             Help
            </HashLink>
            <HashLink
              to="/#contact"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              className="text-gray-800 hover:text-yellow-400 block px-3 py-2 rounded-md text-base font-medium cursor-pointer"
              onClick={() => setIsOpen(false)}
            >
              Contact Us
            </HashLink>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
