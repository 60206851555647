import React from 'react';
import './Loan.css'; // Custom CSS file for styling
import loanImage from "../../assets/loanimage.png"; 
import loanreq from "../../assets/loan/loanrequest.png"
import loanapp from "../../assets/loan/loanapproval.png"
const loanFeatures = [
  {
    id: 1,
    icon: loanreq,
    title: 'Flexible Loan and Advance Grants : ',
    description: 'Easily approve loans or advances based on employee salaries ',
 
  },
  {
    id: 2,
    icon: loanapp,
    title: 'Quick Approval and Repayment : ',
    description: 'Loan approvals with monthly deductions or flexible repayment plans.',

  },
 
];

const Loan = () => {
  return (
    <div className="loan-card bg-custom-bg mt-10 mb-10 mx-auto">
      <h4 className="text-xl font-bold text-customPurple text-center py-10 px-10">Loan Management</h4>

      <div className="loan-container">
        {/* Left Section: Features */}
        <div className="features-section">
          {loanFeatures.map((feature) => (
            <div key={feature.id} className="feature-item flex items-start">
              <div className="feature-icon mr-0 flex-shrink-0"> <img src={feature.icon} className='feature-icon  '/></div>
              <div className="feature-content flex flex-col justify-center">
                <h3 className='text-sm '>{feature.title}</h3>
                <p className='text-xs leading-10'>{feature.description}</p>
              </div>
            </div>
          ))}
        </div>

        {/* Right Section: Image */}
        <div className="image-section ">
          <img src={loanImage} alt="Loan Management" />
        </div>
      </div>
    </div>
   
  );
};

export default Loan;
