import React from "react";
import "./Leave.css"; // Make sure to create this CSS file
import leaveImage from "../../assets/leaveimage.png"; // Replace with your actual image path

const Leave = () => {
  const features = [
    "Users can submit leave requests, with status updates as Pending or Approved.",
    "Choose leave types: Paid Leave, Unpaid Leave, and Sick Leave.",
    "Sends automated notifications about leave request status.",
    "Tracks and stores a history of all leave requests for users.",
    
  ];

  return (
  
    <div className="container flex flex-col items-center justify-start">
     
  <h4 className="text-xl font-bold text-customPurple">Leave Management</h4> 
    
    <div className="flex justify-between w-full"> 
      <div className="features top-left text-xs leading-6 sm:leading-10  bg-custom-bg rounded-xl">{features[0]}</div>
      <div className="features top-right text-xs leading-6 sm:leading-10 bg-custom-bg rounded-xl">{features[1]}</div>
    </div>
    
    <img src={leaveImage} alt="Leave" className="mobile-image w-[450px] h-[450px] my-10 " />
    
    <div className="flex justify-between w-full"> {/* Flexbox for features */}
      <div className="features bottom-left text-xs leading-6 sm:leading-10 bg-custom-bg rounded-xl">{features[2]}</div>
      <div className="features bottom-right text-xs leading-6 sm:leading-10 bg-custom-bg rounded-xl">{features[3]}</div>
    </div>
  </div>
  
  );
};

export default Leave;
