import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Layout from "./components/Layout/Layout";
import Title from "./components/Title/Title";
import Pricing from "./components/Pricing/Pricing";
import Contact from "./components/Contact/Contact";
import Hero2 from "./components/Hero2/Hero2";
import Features2 from "./components/ImageGallery/Features2";
import MoreApps from "./components/MoreApps/MoreApps";
import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicy"; // Import PrivacyPolicy component
import TermsAndConditions from "./components/TermsAndCondition/TermsAndCondition";
import Faq from "./components/Faq/Faq";
import Attendence from "./components/Attendence/Attendence";
import Leave from "./components/Leave/Leave";
import Loan from "./components/Loan/Loan";
import Expenses from "./components/Expenses/Expenses";
import Messages from "./components/Messages/Messages";
import Reports from "./components/Reports/Reports";
import Testimonials from "./components/Testimonials/Testimonials"
import Partners from "./components/Partners/Partners"

const App = () => {
  return (
    <Router basename="/">
      <Layout>
        <Routes>
          <Route
            path="/"
            element={
              <>
                <div className="maincont">
                  <Hero2 />
                  <Attendence/>
                  <Leave/>
                  <Loan/>
                  <Expenses/>
                  <Messages/>
                  <Reports/>
            
                  {/* <Features2 /> */}
                  <Title
              
                  />

                  <Pricing />
                 <Partners/>
          
                <Testimonials/>
                <Faq />
                <Contact />
                <MoreApps />
                </div>
           
              </>
            }
          />
          <Route path="/privacy" element={<PrivacyPolicy />} />{" "}
          {/* Define the route for PrivacyPolicy */}
          <Route path="/terms" element={<TermsAndConditions />} />
        </Routes>
      </Layout>
    </Router>
  );
};

export default App;
