import React from "react";
import "./Expenses.css"; // Make sure to create this CSS file
import expensesmain from "../../assets/expensesmain.png"; // Replace with your actual image path

const Expense = () => {
  const features = [
    "Review employee expenses daily across various categories for better oversight",
    "Monitor expenses for local visit, travel, lodging, and daily allowances",
    "Obtain in-depth reports for better insights into every expense.",
    "Improve financial management with organized expense tracking",
  ];

  return (
  
    <div className="container flex flex-col items-center justify-start">
     
  <h4 className="text-xl font-bold text-customPurple mb-10">Expense Management</h4> 
    
    <div className="flex justify-between w-full"> 
      <div className="featureexp top-left text-xs leading-6 sm:leading-10 bg-custom-bg rounded-xl">{features[0]}</div>
      <div className="featureexp top-right text-xs leading-6 sm:leading-10 bg-custom-bg rounded-xl">{features[1]}</div>
    </div>
    
    <img src={expensesmain} alt="Leave" className="mobile-imageexp  w-full h-auto sm:w-[400px] sm:h-[420px]  " />
    
    <div className="flex justify-between w-full"> {/* Flexbox for features */}
      <div className="featureexp bottom-left text-xs leading-6 sm:leading-10 bg-custom-bg rounded-xl">{features[2]}</div>
      <div className="featureexp bottom-right text-xs leading-6 sm:leading-10 bg-custom-bg rounded-xl">{features[3]}</div>
    </div>
  </div>
  
  );
};

export default Expense;
