import React, { useEffect, useState } from 'react';
import "./Pricing.css"; // Import your custom CSS

const Pricing = () => {
  const [plans, setPlans] = useState([]);

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const response = await fetch('https://wbtechindia.com/apis/masterportallin/plan/upgrade/Active', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ SoftwareID: 8 }), // Change SoftwareID as needed
        });
        const data = await response.json();
        
        if (data.status === 200 && data.data) {
          setPlans(data.data);
        }
      } catch (error) {
        console.error('Error fetching plans:', error);
      }
    };

    fetchPlans();
  }, []);

  // Array of feature names to hide
  const hiddenFeatures = ["Annual Reports", "Notification"];
  const customOrder = ['Trial Plan', 'EasyPagar Basic', 'EasyPagar Plus', 'EasyPagar Pro', 'Intro Plan'];

  // Sort plans based on custom order
  const sortedPlans = plans.sort((a, b) => {
    return customOrder.indexOf(a.Name) - customOrder.indexOf(b.Name);
  });

  // Define custom order for features
  const featureOrder = [
    "Employee",
    "Branch",
    "Attendance",
    "Leave",
    "Loan",
    "Expense",
    "Reports",
    "Messages",
    "Advertise",
    "Role Creation"
  ];

  return (
    <div className="pricing-table scroll-mt-[100px]" id="pricing">
      <h4 className="text-xl font-bold text-customPurple text-center py-2 px-2">Plans & Pricing</h4>
      <h4 className="text-md font-bold text-text-secondary text-center py-6 px-4">Select the plan that best fits your organization's needs</h4>
      <div className="table-wrapper overflow-x-auto">
        <div className="table-header">
          <div className="header-item header-mobile">Features</div>
          {sortedPlans.map((plan) => (
            <div className="header-item text-xs mobile-subplan leading-10" key={plan._id}>
              {/* Display Plan Name */}
              <div>{plan.Name}</div>
              {/* Display Amount Below Plan Name */}
              <div className="text-xs pt-2">₹{plan.Amount}/ {plan.Duration} Days </div> 
            </div>
          ))}
        </div>
        <div className="table-content">
          {featureOrder.map((featureName) => {
            // Check if feature should be hidden
            if (hiddenFeatures.includes(featureName)) {
              return null;
            }

            return (
              <div className="table-row" key={featureName}>
                <div className="feature-items bg-pricie-bg text-white text-center px-2 py-4 text-xs">{featureName}</div>
                {sortedPlans.map((plan) => {
                  // Find the feature in the current plan
                  const currentFeature = plan.AllFeatures.find(f => f.FeatureName === featureName);

                  return (
                    <div className="availability text-xs" key={plan._id}>
                      {currentFeature 
                        ? (currentFeature.Count ? currentFeature.Count : '✔️') 
                        : '❌'}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Pricing;
